var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('p',{staticClass:"text-muted font-13 mb-0"}),_c('div',[_c('good-table-column-search',{attrs:{"moduleapi":((this.baseApi) + "/legalApprovalList/purchase"),"title":"Approval List","columns":_vm.columns,"routerpath":"/legal/purchaselegal","formOptions":_vm.formOptions,"noAddButton":true,"canDelete":0,"noDelete":true,"noAction":true},scopedSlots:_vm._u([{key:"action",fn:function(ref){
var row = ref.row;
var getGridData = ref.getGridData;
return [_c('div',{staticClass:"d-flex"},[(
                      _vm.$store.state.app.user_data &&
                      _vm.$store.state.app.user_data.role == 'admin' &&
                      row.approval_status != 1
                    )?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"primary","id":'approve' + row.id},on:{"click":function($event){return _vm.handleOpenModal(row, getGridData)}}},[(row.loading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1):_vm._e(),(_vm.$store.state.app.user_data && row.approval_status != 1)?_c('b-tooltip',{attrs:{"target":'approve' + row.id,"triggers":"hover"}},[_vm._v(" Approve ")]):_vm._e(),(_vm.$store.state.app.user_data && row.approval_status != 2)?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"danger","id":'reject' + row.id},on:{"click":function($event){return _vm.handleApprove(row, 2, getGridData)}}},[(row.loading)?_c('b-spinner',{attrs:{"small":"","variant":"light"}}):_c('feather-icon',{attrs:{"icon":"XIcon"}})],1):_vm._e(),(_vm.$store.state.app.user_data && row.approval_status != 2)?_c('b-tooltip',{attrs:{"target":'reject' + row.id,"triggers":"hover"}},[_vm._v(" Reject ")]):_vm._e(),(row.approval_status == 1)?_c('b-badge',{staticClass:"mr-50 cursor-pointer",attrs:{"variant":"warning","id":'view' + row.id},on:{"click":function($event){return _vm.handleView(row)}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon"}})],1):_vm._e(),(row.approval_status == 1)?_c('b-tooltip',{attrs:{"target":'view' + row.id,"triggers":"hover"}},[_vm._v(" View ")]):_vm._e()],1)]}},{key:"customField",fn:function(ref){
                    var props = ref.props;
return [(props.column.field === 'villagename')?_c('span',[_vm._v(" "+_vm._s(props.row.purchase && props.row.purchase.villagename ? props.row.purchase.villagename.villagename : "")+" ")]):(props.column.field === 'surveynonew')?_c('span',[_vm._v(" "+_vm._s(props.row.purchase && props.row.purchase.surveynonew ? props.row.purchase.surveynonew : "")+" ")]):(props.column.field === 'surveynoold')?_c('span',[_vm._v(" "+_vm._s(props.row.purchase && props.row.purchase.surveynoold ? props.row.purchase.surveynoold : "")+" ")]):(props.column.field === 'legal_status')?_c('span',[_vm._v(" "+_vm._s(_vm.handleLegalStatus(props.row.legal_status))+" ")]):(props.column.field == 'approval_status')?_c('span',[_c('b-badge',{attrs:{"variant":_vm.handleColor(props.row.approval_status)}},[_vm._v(" "+_vm._s(_vm.handleApprovalStatus(props.row.approval_status)))])],1):_vm._e()]}}])})],1)])])])]),_c('b-modal',{attrs:{"id":"modal-form","size":"lg","cancel-variant":"outline-secondary","ok-title":"Submit","cancel-title":"Close","title":"Legal Documents","hide-footer":""},model:{value:(_vm.popUp),callback:function ($$v) {_vm.popUp=$$v},expression:"popUp"}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Document No.")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Document No."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":_vm.view,"placeholder":"Enter Number"},model:{value:(_vm.doc_number),callback:function ($$v) {_vm.doc_number=$$v},expression:"doc_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Document")]),_c('b-form-file',{attrs:{"placeholder":"Select Document","disabled":_vm.view,"drop-placeholder":"Drop file here..."},on:{"input":function($event){return _vm.handleFileChange($event)}},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('attachment',{attrs:{"data":_vm.file}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Date")]),_c('label',{staticStyle:{"color":"red !important"}},[_vm._v("*")]),_c('validation-provider',{attrs:{"rules":"required","name":"Date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",staticStyle:{"background":"transparent"},attrs:{"placeholder":"Select Date","disabled":_vm.view,"config":{
                  enableTime: false,
                  dateFormat: 'd/m/Y',
                }},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',[_vm._v("Remarks")]),_c('b-form-textarea',{attrs:{"disabled":_vm.view,"placeholder":"Enter Remarks","rows":"3"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}})],1)],1)],1)],1),(!_vm.view)?_c('b-button',{staticClass:"mt-2",attrs:{"variant":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.formSubmit.apply(null, arguments)}}},[_vm._v(" Submit ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }