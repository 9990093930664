<template>
  <div>
    <b-tabs class="pt-1">
    <b-tab active>
      <template #title>
        <feather-icon icon="HomeIcon" />
        <span>Home</span>
      </template>
      <div class="text-center">
      <!-- Avatar -->
      <b-avatar
        size="72px"
        class="mr-1"
        :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')"
      />

      <!-- Name -->
      <b-card-text class="mt-2 h4 color-inherit text-reset">
        Black Widow
      </b-card-text>
    </div>

    <b-list-group class="mt-3 rounded-0">
      <b-list-group-item
        v-for="item in sidebarItems"
        :key="item.title"
        class="bg-transparent"
      >
        <feather-icon
          class="align-text-bottom"
          :icon="item.icon"
        />
        <span
          class="ml-1"
          v-text="item.title"
        />
      </b-list-group-item>
    </b-list-group>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>Services</span>
      </template>

      <b-card-text>
        Muffin cupcake candy chocolate cake gummi bears fruitcake donut dessert pie. Wafer toffee bonbon dragée. Jujubes cotton candy gummies chupa chups. Sweet fruitcake cheesecake biscuit cotton candy. Cookie powder marshmallow donut. Candy cookie sweet roll bear claw sweet roll. Cake tiramisu cotton candy gingerbread cheesecake toffee cake. Cookie liquorice dessert candy canes jelly.
      </b-card-text>
      <b-card-text>
        Sweet chocolate muffin fruitcake gummies jujubes pie lollipop. Brownie marshmallow caramels gingerbread jelly beans chocolate bar oat cake wafer. Chocolate bar danish icing sweet apple pie jelly-o carrot cake cookie cake.
      </b-card-text>
    </b-tab>
  </b-tabs>
  </div>
</template>

<script>
import {
  BAvatar, BListGroup, BListGroupItem, BCardText,
    BTabs,
    BTab,
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar,
    BListGroup,
    BListGroupItem,
    BCardText,
    BTabs,
    BTab,

  },
  data() {
    return {
      sidebarItems: [
        { title: 'Dashboard', icon: 'GridIcon' },
        { title: 'Analytics', icon: 'ActivityIcon' },
        { title: 'History', icon: 'RefreshCwIcon' },
        { title: 'Configuration', icon: 'SettingsIcon' },
        { title: 'Profile', icon: 'UserIcon' },
      ],
    }
  },
}
</script>
