<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/legalApprovalList/purchase`"
                title="Approval List"
                :columns="columns"
                routerpath="/legal/purchaselegal"
                :formOptions="formOptions"
                :noAddButton="true"
                :canDelete="0"
                :noDelete="true"
                :noAction="true"
              >
                <template #action="{ row, getGridData }">
                  <div class="d-flex">
                    <b-badge
                      v-if="
                        $store.state.app.user_data &&
                        $store.state.app.user_data.role == 'admin' &&
                        row.approval_status != 1
                      "
                      variant="primary"
                      :id="'approve' + row.id"
                      class="mr-50 cursor-pointer"
                      @click="handleOpenModal(row, getGridData)"
                    >
                      <b-spinner v-if="row.loading" small variant="light" />
                      <feather-icon v-else icon="CheckIcon" />
                    </b-badge>
                    <b-tooltip
                      v-if="$store.state.app.user_data && row.approval_status != 1"
                      :target="'approve' + row.id"
                      triggers="hover"
                    >
                      Approve
                    </b-tooltip>

                    <b-badge
                      v-if="$store.state.app.user_data && row.approval_status != 2"
                      variant="danger"
                      :id="'reject' + row.id"
                      class="mr-50 cursor-pointer"
                      @click="handleApprove(row, 2, getGridData)"
                    >
                      <b-spinner v-if="row.loading" small variant="light" />
                      <feather-icon v-else icon="XIcon" />
                    </b-badge>

                    <b-tooltip
                      v-if="$store.state.app.user_data && row.approval_status != 2"
                      :target="'reject' + row.id"
                      triggers="hover"
                    >
                      Reject
                    </b-tooltip>

                    <b-badge
                      v-if="row.approval_status == 1"
                      variant="warning"
                      :id="'view' + row.id"
                      class="mr-50 cursor-pointer"
                      @click="handleView(row)"
                    >
                      <feather-icon icon="EyeIcon" />
                    </b-badge>
                    <b-tooltip
                      v-if="row.approval_status == 1"
                      :target="'view' + row.id"
                      triggers="hover"
                    >
                      View
                    </b-tooltip>
                  </div>
                </template>
                <template #customField="{ props }">
                  <span v-if="props.column.field === 'villagename'">
                    {{
                      props.row.purchase && props.row.purchase.villagename
                        ? props.row.purchase.villagename.villagename
                        : ""
                    }}
                  </span>

                  <span v-else-if="props.column.field === 'surveynonew'">
                    {{
                      props.row.purchase && props.row.purchase.surveynonew
                        ? props.row.purchase.surveynonew
                        : ""
                    }}
                  </span>
                  <span v-else-if="props.column.field === 'surveynoold'">
                    {{
                      props.row.purchase && props.row.purchase.surveynoold
                        ? props.row.purchase.surveynoold
                        : ""
                    }}
                  </span>
                  <span v-else-if="props.column.field === 'legal_status'">
                    {{ handleLegalStatus(props.row.legal_status) }}
                  </span>
                  <span v-else-if="props.column.field == 'approval_status'">
                    <b-badge :variant="handleColor(props.row.approval_status)">
                      {{ handleApprovalStatus(props.row.approval_status) }}</b-badge
                    >
                  </span>
                </template>
              </good-table-column-search>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modal-form"
      size="lg"
      v-model="popUp"
      cancel-variant="outline-secondary"
      ok-title="Submit"
      cancel-title="Close"
      title="Legal Documents"
      hide-footer
    >
      <validation-observer ref="simpleRules">
        <b-row>
          <b-col md="6">
            <b-form-group>
              <label>Document No.</label>
              <label style="color: red !important">*</label>
              <validation-provider
                #default="{ errors }"
                rules="required"
                name="Document No."
              >
                <b-form-input
                  v-model="doc_number"
                  :disabled="view"
                  placeholder="Enter Number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Document</label>
              <b-form-file
                v-model="file"
                placeholder="Select Document"
                :disabled="view"
                drop-placeholder="Drop file here..."
                @input="handleFileChange($event)"
              />
            </b-form-group>
            <attachment :data="file" />
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Date</label>
              <label style="color: red !important">*</label>
              <validation-provider #default="{ errors }" rules="required" name="Date">
                <flat-pickr
                  v-model="date"
                  placeholder="Select Date"
                  class="form-control"
                  :disabled="view"
                  :config="{
                    enableTime: false,
                    dateFormat: 'd/m/Y',
                  }"
                  style="background: transparent"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <label>Remarks</label>
              <b-form-textarea
                v-model="remarks"
                :disabled="view"
                placeholder="Enter Remarks"
                rows="3"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <b-button v-if="!view" variant="primary" @click.prevent="formSubmit" class="mt-2">
        Submit
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import GoodTableColumnSearch from "../../../components/GoodTableColumnSearch.vue";
// import action from './action.vue'
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Attachment from "../../../components/Attechment.vue";
import axios from "axios";
const baseApi = process.env.VUE_APP_APIENDPOINT;
import {
  BFormRadio,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BBadge,
  BTooltip,
  BFormTextarea,
  BFormFile,
  BFormInput,
  BRow,
  BCol,
  BButton,
  BSpinner,
} from "bootstrap-vue";
import moment from "moment";
export default {
  components: {
    GoodTableColumnSearch,
    BTooltip,
    BSpinner,
    BModal,
    Attachment,
    // action,
    BFormRadio,
    BFormRadioGroup,
    BFormGroup,
    BBadge,
    flatPickr,
    BFormTextarea,
    BFormFile,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    handleApprovalStatus() {
      const obj = ["Pending", "Approved", "Rejected"];
      return (status) => obj[status];
    },
    handleColor() {
      const obj = ["light-secondary", "light-success", "light-danger"];
      return (status) => obj[status];
    },
  },
  data() {
    return {
      disabled: false,
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: "Village",
          field: "villagename",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
        },
        {
          label: "New Survey No.",
          field: "surveynonew",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Old Survey No.",
          field: "surveynoold",
          filterOptions: {
            enabled: true,
            placeholder: "Search",
          },
        },
        {
          label: "Legal Status",
          field: "legal_status",
          type: "dropdown",
          responseValue: "name",
          options: [
            { name: "Agreement to Sale", id: 6 },
            { name: "Sale Deed for Execution", id: 11 },
            { name: "POA (Power of Attorney)", id: 12 },
          ],
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Approval Status",
          field: "approval_status",
          type: "dropdown",
          responseValue: "name",
          options: [
            { name: "Pending", id: 0 },
            { name: "Approved", id: 1 },
            { name: "Rejected", id: 2 },
          ],
          filterOptions: {
            enabled: true,
            placeholder: "Search Status",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "created_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          url: "getUsers",
          responseValue: "fullname",
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Updated Date",
          field: "updated_date",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
          filterOptions: {
            enabled: false,
            placeholder: "Search Date",
          },
        },
      ],
      formOptions: {
        submitRouterPath: "/setup/language",
        formTitle: "KYC",
        usersDropdown: true,
        method: "post",
        action: "add",
        gridForm: true,
        url: `${baseApi}/language`,
        inputFields: [],
      },
      popUp: false,
      gridFnc: () => {},
      selectedRaw: {},
      file: "",
      date: "",
      doc_number: "",
      remarks: "",
      accessToken: localStorage.getItem("accessToken"),
      view: false,
    };
  },
  methods: {
    handleView(row) {
      this.view = true;
      this.popUp = true;
      this.file = row.file;
      this.date = row.date;
      this.doc_number = row.doc_number;
      this.remarks = row.remarks;
    },
    formSubmit() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          this.handleApprove(this.selectedRaw, 1, this.gridFnc);
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: "Please fill in all the fields",
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    async handleOpenModal(raw, func) {
      this.popUp = !this.popUp;
      this.gridFnc = func;
      this.selectedRaw = raw;
      this.disabled = false;
      this.view = false;

      this.file = "";
      this.date = "";
      this.doc_number = "";
      this.remarks = "";
    },

    async handleFileChange(e, type) {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      const formData = new FormData();
      formData.append("image", e);
      formData.append("type", type);
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
        data: formData,
        url: `${baseApi}/attachment`,
      };
      await axios(requestOptions)
        .then((response) => {
          this.file = process.env.VUE_APP_IMAGE_PATH + response.data.attachment;
        })
        .catch((error) => console.log(error, "error"));
    },
    async handleApprove(row, approve, getGridData) {
      this.disabled = true;
      !row.loading &&
        this.$swal({
          title: `Are you sure to ${approve == 1 ? "Approve" : "Reject"}?`,
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `${approve == 1 ? "Approve" : "Reject"}`,
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })
          .then(async (result) => {
            const data = {
              approval_status: approve,
              remarks: this.remarks,
              file: this.file,
              date: this.date ? moment(this.date, "DD/MM/YYYY").format("YYYY-MM-DD") : "",
              doc_number: this.doc_number,
            };
            row.loading = true;
            if (result.value) {
              await axios({
                method: "post",
                url: `${this.baseApi}/updateLegalApprovalStatus/${row.id}`,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
                data: JSON.stringify(data),
              })
                .then((json) => {
                  if (json.data.status == 200 || json.data) {
                    this.popUp = false;
                    this.$swal({
                      icon: "success",
                      title: `${approve == 1 ? "Approved" : "Rejected"}`,
                      text: `Your data has been ${
                        approve == 1 ? "Approved" : "Rejected"
                      }.`,
                      customClass: {
                        confirmButton: "btn btn-success",
                      },
                    });
                    getGridData();
                  }
                })
                .catch((error) =>
                  this.$swal({
                    title: "Error!",
                    text: `${error}`,
                    icon: "error",
                    timer: 5000,
                    customClass: {
                      confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                  })
                )
                .finally(() => {
                  row.loading = false;
                  this.disabled = false;
                  this.$forceUpdate();
                });
            }
          })
          .catch((error) => console.log(error, "error"));
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
